/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Heading, HeadingSize } from '../components/shared/text/Heading';
import MarkdownRenderers from './MarkdownRenderers';

export default {
  ...MarkdownRenderers,
  h5(props: any) {
    return (
      <Heading size={`h${props.level}` as HeadingSize}>
        <span className="text-accent-1 text-dpm-18 font-medium">{props.children}</span>
      </Heading>
    );
  },
  ol(props: any) {
    return <ol className="list-inside list-decimal [&>li>p:first-child]:inline-block [&>li>p]:my-1 [&>li>p]:ml-3">{props.children}</ol>;
  },
  ul(props: any) {
    return (
      <ul className="list-inside list-disc [&>li>p:first-child]:ml-0 [&>li>p:first-child]:inline-block [&>li>p]:my-1 [&>li>p]:ml-6">
        {props.children}
      </ul>
    );
  },
  table(props: any) {
    return (
      <table className="w-full text-left" cellSpacing="4" cellPadding="4">
        {props.children}
      </table>
    );
  },
  td(props: any) {
    return <td className="align-top">{props.children}</td>;
  },
};
